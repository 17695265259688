import React, { useState } from 'react';
import { NavBrand } from '../components/NavBrand/NavBrand';
import { NavToggle } from '../components/NavToggle/NavToggle';
import { NavbarProfile } from '../MobileNavbar/NavbarProfile/NavbarProfile';
import { DesktopMenu } from './DesktopMenu/DesktopMenu';

import './DesktopNavbar.scss';

export const DesktopNavbar = ({ isDesktop }) => {
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);

  return (
    <>
      <nav
        className="dn-root"
        style={{
          boxShadow: 'unset',
          borderBottom: '0.5px solid #D6D6D6',
        }}>
        <div className="container">
          <NavToggle
            onClick={() => {
              setOffcanvasOpen((prev) => !prev);
            }}
            isOffcanvasOpen={false}
          />

          <NavBrand />

          <DesktopMenu
            isOffcanvasOpen={isOffcanvasOpen}
            isDesktop={isDesktop}
            onClick={() => {
              setOffcanvasOpen((prev) => !prev);
            }}
          />

          <NavbarProfile />
        </div>
      </nav>

      <div
        className={`offcanvas-backdrop' ${isOffcanvasOpen ? 'show' : ''}`}
        onClick={() => {
          setOffcanvasOpen((prev) => !prev);
        }}
      ></div>
    </>
  );
};

import React, { useState } from 'react';
import { useNavbarContext } from '../../Navbar';
import { NavList } from '../DesktopList/NavList';
import { NavBrand } from '../../components/NavBrand/NavBrand';
import { NavToggle } from '../../components/NavToggle/NavToggle';

import './DesktopMenu.scss';

export const DesktopMenu = (props) => {
  const { finalMenu, activeLinks } = useNavbarContext();
  const [activeItem, setActiveItem] = useState(null);
  const { isOffcanvasOpen = false, onClick, isDesktop } = props;

  return (
    <div
      className={`dm-root ${isOffcanvasOpen && 'is-open'}`}
      style={{
        height: '100%',
      }}
      onMouseLeave={() => {
        setActiveItem(undefined);
      }}
    >
      <div className={'dm-root-header'}>
        <NavToggle onClick={onClick} isOffcanvasOpen={isOffcanvasOpen} />

        <NavBrand />
      </div>

      <div
        className={'dm-root-body'}
        style={{
          height: '100%',
        }}
      >
        <ul
          className={'ul_parent'}
          style={{
            height: '100%',
          }}
        >
          {finalMenu.map((item) => (
            <NavList
              level={0}
              key={item.title}
              item={item}
              onMouseOver={() => {
                isDesktop
                  ? setActiveItem(item)
                  : setTimeout(() => {
                    setActiveItem(item);
                  }, 1);
              }}
              onMouseLeave={() => {
                setActiveItem(undefined);
              }}
              isDesktop={isDesktop}
              isSelected={
                (activeItem && activeItem.title === item.title) ||
                activeLinks.includes(item.link)
              }
            />
          ))}
        </ul>
      </div>
    </div>
  );
};
